<template>
  <create-item-component
      endpoint="wows"
      type="wow"/>
</template>
<script>

export default {
  name: "Wows"
}
</script>